import React, { Component } from "react";
import Title from "./Title";
import PriceItem from "./PriceItem";
import { Player, ControlBar } from "video-react";

class Price extends Component {
  constructor() {
    super();
    this.state = {
      posts: [
        {
          id: 100,
          ptitle: "Diário",
          info: "% Retorno Dia",
          rate: "2,40%",
          list1: "Do valor do Objeto",
          list2: "com a garantia Prete"
        },
        {
          id: 101,
          ptitle: "Semanal",
          info: "% Retorno Semana",
          rate: "4.00%",
          list1: "Do valor do Objeto",
          list2: "com a garantia Prete"
        },
        {
          id: 102,
          ptitle: "Quinzenal",
          info: "% Retorno Quinzena",
          rate: "5.60%",
          list1: "Do valor do Objeto",
          list2: "com a garantia Prete"
        },
        {
          id: 103,
          ptitle: "Mensal",
          info: "% Retorno mês",
          rate: "10.40%",
          list1: "Do valor do Objeto",
          list2: "com a garantia Prete"
        }
      ]
    };
  }
  render() {
    return (
      <section className="priceing_area_two" id="pricing">
        <div className="container">
          <Title Stitle="Ganhe dinheiro com os Objetos que estão na sua Casa" pfont="Que tal ter uma renda adicional, sem investimentos, utilizando os objetos que já são seus e ainda melhor, sem precisar vendê-los e conseguindo uma rentabilidade maior que a maioria dos investimentos atuais " />
          <div className="row">
            {this.state.posts.map(post => (
              <PriceItem key={post.id} post={post} image="price-icon-2.png" />
            ))}
          </div>
          <div >
            <Player
              autoPlay={true}
              fluid={false}
              ref={player => {
                this.player = player;
              }}
            >
              <source src="https://storage.googleapis.com/preteapp/PublicImages/Prete%205.m4v"/>
              <ControlBar disableDefaultControls={true} className="my-class" />
            </Player>
          </div>
        </div>
      </section>
    );
  }
}

export default Price;
