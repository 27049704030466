import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            <footer className="footer-five">
                <div className="footer-top">
                    <div className="container">
                        <div className="row footer_sidebar">
                            <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget1 about_us_widget"> 
                                <Link to='/'>
                                    <span className="logo">
                                        <img src={require('../../images/rodape.png')} alt="AppArt"/>
                                    </span>
                                </Link>
                                <p> Plataforma Prete © Copyright 2020 </p>
                            </div>
                            <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget4 widget_social">
                                <h4 className="widget_title_two">Mídias Sociais</h4>
                                <ul className="social-icon">
                                    <li><a href="http://www.facebook.com.br/preteapp"><i className="fa fa-facebook"></i> Facebook</a></li>
                                    <li><a href="http://www.instagram.com/preteapp"><i className="fa fa-instagram"></i> Instagram </a></li>
                                    <li><a href="https://www.linkedin.com/in/prete-m2v-1b40a8185/"><i className="fa fa-linkedin"></i> Linkedin </a></li>
                                    <li><a href="http://www.twitter.com/preteapp"><i className="fa fa-twitter"></i> Twitter </a></li> 
                                    <li><a href="https://www.youtube.com/channel/UCuD3Rq1ZOKpllDwX2aApMjw"><i className="fa fa-youtube"></i> Youtube </a></li>                                     
                                </ul>
                            </div>
                            <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget2 widget_contact">
                                <h4 className="widget_title_two">Informações de Contato</h4>
                                <div className="widget_inner row m0">
                                    <ul>
                                        <li><i className="ti-email"></i>
                                            <div className="fleft contact_mail"><a href="mailto:prete@prete.app"> prete@prete.app </a></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget4 widget_social"> 
                                <h4 className="widget_title_two">Termos PRETE</h4>
                                <div className="social-icon row m0">
                                    <ul>
                                        <li> 
                                        <div> <a href="https://prete.app/Service"> Termos de Serviço e Uso</a></div>
                                        </li>
                                        <li> 
                                        <div> <a href="https://prete.app/Policy"> Política de Privacidade</a></div>
                                        </li>
                                        <li> 
                                        <div> <a href="https://prete.app/Taxes"> Taxas PRETE</a></div>
                                        </li>                                                                                
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row m0 footer_bottom text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;