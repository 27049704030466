import React, { Component } from 'react';

class AppStore extends Component {
    render() {
        return (
            <section className="app-deatails-area">
                <div className="container">
                </div>
            </section>
        );
    }
}

export default AppStore;