import React, { Component } from "react";
import { Link } from "react-router-dom";

class CustomNavbar extends Component {
  render() {
    return (
      <nav id="fixed-top" className="navbar navbar-toggleable-sm transparent-nav navbar-expand-lg">
        <div className="container">
          <Link to="/">
            <span className="navbar-brand">
              <img src={require("../images/iconGoogle.png")} alt="logo" />
              <img src={require("../images/iconGoogle.png")} alt="logo" />
            </span>
          </Link>
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown submenu">
                <a className="nav-link dropdown-toggle active" href="#home" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Principal
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#features">
                  A Plataforma
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#faq">
                  Pegar Emprestado
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#pricing">
                  Empreste e Ganhe
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#download">
                  Baixe o APP
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default CustomNavbar;
