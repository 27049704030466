import React, { Component } from 'react';

class Login extends Component {
    render() {
        let imgUrl = require('../images/get_bg.jpg')
        const divStyle = {
            background: 'linear-gradient(0deg, rgba(76,132,255,0.88), rgba(76,132,255,0.88)), url(' + imgUrl + ') no-repeat',
        }
        return (
          null
        );
    }
}

export default Login;